import React from 'react'
import TALogo from '../../../assets/images/ta-logo.png'

import TAWhiteLogo from '../../../assets/images/ta-white-logo.png'
function Footer() {
  return (
    <div>
      <div className="p-8 md:py-8 md:px-16 lg:px-24  flex flex-col md:flex-row gap-8 items-center justify-between bg-[#8CAF4B]">
        <h2
          className="max-w-3xl text-center md:text-left text-4xl text-white uppercase"
          style={{ fontFamily: `"Cabin", sans-serif`,  }}
        >
          GET IN TOUCH IF YOU HAVE FURTHER inquiries 
        </h2>
        <button className="btn  rounded md:rounded-3xl px-8 text-lg">
          Contact us
        </button>
      </div>
<FooterSocial/>
    
    </div>
  )
}

export default Footer

interface FooterSocial{
  theme?:"light"|"dark"
}
export function FooterSocial(props:FooterSocial){
  const {theme}=props
  return   <div className=" tap-footer-social flex flex-col md:flex-row items-center justify-between p-8 ">
  <div className="flex w-full  justify-center">
  <img src={theme==="light"?TAWhiteLogo:TALogo} alt="" />
  </div>
  <ul className="flex flex-col md:flex-row justify-center  items-center w-full p-4 gap-4 md:gap-4">
    <li className=''>
      <p>Support</p>
    </li>
    <li>
      <p>Privacy Policy</p>
    </li>
    <li>
      <p>Terms and Condition</p>
    </li>
  </ul>
  <p className="text-center md:text-right w-full">© 2024 TAP All Rights Reserved</p>
</div>
}