import React from 'react';
import UpdatedNavbar from '../../layout/UpdateNavbar';
import { Container } from "react-bootstrap";
import Header from "../../components/Header";
import Paragraph from "../../components/Paragraph";
import TopTalentSection from './components/TopTalentSection';
import Footer from '../../layout/Footer';

const ForCompanies = () => {

  return (
    <div className='force-white-mode'>
        <UpdatedNavbar />   
        <div className="job-page-image md:px-5" style={{marginTop: 0}}>
        <Container>
          <section className='grid pb-5 grid-cols-1 md:grid-cols-2  gap-3'>
            <div style={{ marginTop: "15%" }}>
              <Header
                titleStyles={HeaderStyling}
                title="Hire the best-accurate, fair and fast recruitment!"
              />
              <Paragraph
                text="Our screening tests identify the best candidates and make your hiring decisions faster, easier, and bias-free."
                textStyles={textStyles}
              />
              <div className='mt-3 w-[300px]'>
                 <button className='md:-ml-2 button-md'>
                    Request A Demo
                 </button>
              </div>
            </div>
            <div className='companies-banner-image hidden md:block md:ml-[150px] md:mt-8'>
            </div>
          </section>
        </Container>
      </div>
      <TopTalentSection />
      <Footer />
    </div>
  )
}

const HeaderStyling = {
  color: "#19144D",
  // textAlign: 'center'
};

const textStyles = {
  color: "#19144D",
  fontSize: 26
};

export default ForCompanies