import React from 'react'
import { Dropdown, Ripple, initTE, } from "tw-elements";
import CustomDropdown from '../../../components/CustomDrpdown';

const LoginDrpdown = ({isSubmitting, setAccounType, accountType}) => {

 React.useEffect( () => initTE({ Ripple, Dropdown }) , [])


  return (
    <>
      {/*<div className='flex mt-4 items-center'>
         <h5 className='mr-3'>Login as:</h5>
         <CustomDropdown
            onChange={ (value) => {
                  setAccounType(value.value)
            } }
            options={[
               { value: 'candidate' , label: 'Candidate'},
               { value: 'admin' , label: 'Client'}
            ]}
         />
         </div>*/}
    <div>  
        <button
            class="flex items-center justify-center mx-auto mt-4 mb-4 w-full my-3 whitespace-nowrap rounded bg-armyGreen px-16 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#14a44d] transition duration-150 ease-in-out hover:bg-success-600 hover:shadow-[0_8px_9px_-4px_rgba(20,164,77,0.3),0_4px_18px_0_rgba(20,164,77,0.2)] focus:bg-success-600 focus:shadow-[0_8px_9px_-4px_rgba(20,164,77,0.3),0_4px_18px_0_rgba(20,164,77,0.2)] focus:outline-none focus:ring-0 active:bg-success-700 active:shadow-[0_8px_9px_-4px_rgba(20,164,77,0.3),0_4px_18px_0_rgba(20,164,77,0.2)] motion-reduce:transition-none"
            type="submit"
            id="dropdownMenuButton5"
            disabled={isSubmitting}
           >
              Login
        </button>
        </div>
        
    </>
  )
}

export default LoginDrpdown