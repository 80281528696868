import React from "react";
import { Collapse, Dropdown, initTE } from "tw-elements";
import Logo from "../components/Logo";
import { Link, useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { api } from "./../api/index";
import { compareSync } from "bcryptjs";

const UpdatedNavbar = () => {
  const [isAuth, setIsAuth] = React.useState(
    () => parseInt(sessionStorage.getItem("is-authenticated")) || 0
  );

  React.useEffect(() => initTE({ Collapse, Dropdown }), []);
  const location = useLocation();
  const navigate = useNavigate();
  const isActiveLink = (path) => location.pathname.includes(path);
  const accountType = sessionStorage.getItem("account-type");

  React.useEffect(() => {
    const checkAndUpdateAuthStatus = () => {
      const authStatus =
        parseInt(sessionStorage.getItem("is-authenticated")) || 0;
      setIsAuth(authStatus);
    };

    const intervalId = setInterval(checkAndUpdateAuthStatus, 2000);

    checkAndUpdateAuthStatus();

    return () => clearInterval(intervalId);
  }, []);

  const handleLogout = async () => {
    toast.loading("Logging you out...");
    try {
      const data = await api.logout();
      toast.remove();
      toast.success(data?.message);
      sessionStorage.clear()
      navigate("/");
    } catch ({ response }) {
      if (response.status === 401) {
        sessionStorage.clear()
        navigate("/login");
      } else {
        toast.error(response?.data?.message);
      }
      toast.remove();
    }
  };

  return (
    <nav
      className={`${
        isActiveLink("/job-listings/apply") ||
        isActiveLink("/about-us") || isActiveLink("/submit-application") ||
        isActiveLink("/login") || isActiveLink("/register") ||
        isActiveLink("/bookmarks") 
          ? "shadow-md"
          : ""
      } 
        relative flex w-full mt-0 flex-nowrap items-center justify-between bg-[#FBFBFB] py-3 text-neutral-500 hover:text-neutral-700 focus:text-neutral-700 dark:bg-white lg:flex-wrap lg:justify-start lg:py-4`}
      data-te-navbar-ref
    >
      <div class="flex w-full flex-wrap items-center justify-between px-1">
        <Link to={"/"}>
          <Logo height="38" />
        </Link>

        <button
          class="block border-0 bg-transparent px-2 text-neutral-500 hover:no-underline hover:shadow-none focus:no-underline focus:shadow-none focus:outline-none focus:ring-0  lg:hidden"
          type="button"
          id="button"
          data-te-collapse-init
          data-te-target="#navbarSupportedContent14"
          aria-controls="navbarSupportedContent14"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="[&>svg]:w-7">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              class="h-7 w-7"
            >
              <path
                fill-rule="evenodd"
                d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
                clip-rule="evenodd"
              />
            </svg>
          </span>
        </button>

        <div
          class="!visible mt-2 mt-md-0 hidden justify-end flex-grow basis-[100%] items-center lg:mt-0 lg:!flex md:basis-auto"
          id="navbarSupportedContent14"
          data-te-collapse-item
        >
          <ul
            class="list-style-none mr-auto flex flex-col pl-0 lg:mt-0 lg:flex-row md:w-full md:justify-end md:items-center"
            data-te-navbar-nav-ref
          >
            <li class="my-0 pl-2 lg:pl-2 lg:pr-1" data-te-nav-item-ref>
              <Link
                to="/"
                id={isActiveLink("home") ? "active-link" : ""}
                className="px-2 px-sm-2 text-sm px-lg-2 nav-link d-flex align-items-center"
                style={{ color: "#222" }}
              >
                {" "}
                Home
              </Link>
            </li>
            <li class="my-0 pl-2 lg:pl-2 lg:pr-1" data-te-nav-item-ref>
              <Link
                to="/about-us"
                id={isActiveLink("/about-us") ? "active-link" : ""}
                className="px-2 px-sm-2 text-sm px-lg-2 nav-link d-flex align-items-center"
                style={{ color: "#222" }}
              >
                About Us
              </Link>
            </li>
            <li class="my-0 pl-2  lg:pl-2 lg:pr-1" data-te-nav-item-ref>
              <Link
                to="/job-listings"
                id={isActiveLink("/job-listings") ? "active-link" : ""}
                className="px-2 px-sm-2 text-sm px-lg-2 nav-link d-flex align-items-center"
                style={{ color: "#222" }}
              >
                {" "}
                Find Jobs
              </Link>
            </li>
            <li class="my-0 pl-2  lg:pl-2 lg:pr-1" data-te-nav-item-ref>
              <Link
                to="/for-companies"
                id={isActiveLink("/for-companies") ? "active-link" : ""}
                className="px-2 px-sm-2 text-sm px-lg-2 nav-link d-flex align-items-center"
                style={{ color: "#222" }}
              >
                {" "}
                For Companies
              </Link>
            </li>
            <li class="my-0 pl-2  lg:pl-2 lg:pr-1" data-te-nav-item-ref>
              <Link
                to="/frequently-asked-questions"
                id={isActiveLink("/frequently-asked-questions") ? "active-link" : ""}
                className="px-2 px-sm-2 text-sm px-lg-2 nav-link d-flex align-items-center"
                style={{ color: "#222" }}
              >
                {" "}
                FAQS
              </Link>
            </li>
            {accountType ? (
              compareSync("candidate", accountType) ? (
                <Link
                  to="/bookmarks"
                  id={isActiveLink("/bookmarks") ? "active-link" : ""}
                  className="px-2 px-sm-2 text-sm px-lg-2 nav-link d-flex align-items-center"
                  style={{ color: "#222" }}
                >
                  {" "}
                  Bookmarks
                </Link>
              ) : null
            ) : null}
            {isAuth === 0 ? (
              <li class="my-0 pl-2  lg:pl-3" data-te-nav-item-ref>
                <Link
                  to="/login"
                  className="inline-block w-full md:w-[100px] text-center text-white md:mr-5 bg-armyGreen rounded-lg py-2 text-sm"
                  style={{ color: "#222" }}
                   
                >
                  {" "}
                  Login
                </Link>
              </li>
            ) : (
              <li class="my-0 pl-2  lg:pl-3" data-te-nav-item-ref>
                <button
                  className="inline-block w-full md:w-[100px] text-center text-white md:mr-5 bg-red-500 rounded-lg py-2 text-sm"
                  style={{ color: "#222" }}
                  onClick={handleLogout}
                >
                  {" "}
                  Logout
                </button>
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default UpdatedNavbar;
