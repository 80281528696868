import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import toast from "react-hot-toast";
import { api } from "../../../api";
import { hashSync } from "bcryptjs";
import { useNavigate, useSearchParams } from "react-router-dom";

const VerifyEmail = ({ setScreenId, handleModal }) => {
  
  const navigate = useNavigate()
  const { register, handleSubmit } = useForm();
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');

  const { mutateAsync: VerifyEmail } = useMutation(api.verifyEmail);
  const { mutateAsync: ResendOTP } = useMutation(api.resendOtp);

  const [clientEmail, setClientEmail] = useState("");
  const account_type = "candidate";

  //email for client who just registered
  //const email = sessionStorage.getItem("user-email");
  const password = sessionStorage.getItem("user-password");

  const loginMutation = useMutation(api.login, {
    onMutate: () => {
      // toast.loading("Logging in...");
    },
    onSuccess: (data) => {
      toast.remove();
      toast.success("Logged in successfully...");
      sessionStorage.setItem("userToken", data.data.token);
      sessionStorage.setItem("user", JSON.stringify(data?.data));
      sessionStorage.setItem("account-type", hashSync(account_type, 10));
      sessionStorage.setItem("is-authenticated", "1");
     // setTimeout(() => {
       navigate(-1)
      //}, 500);
      //setTimeout( () => setScreenId(3) , 500 )
      // console.log(data);

      //   navigate(-1);
    },
    onError: (error) => {
      toast.remove();
      toast.error(error.response?.data.error);
    },
  });

  const onSubmit = async (values) => {
    toast.loading("Processing...");
    try {
      //check if client just registered.
      const payload = email
        ? { ...values, email, type: 'otp' }
        : { ...values, email: clientEmail, type: 'otp' };
      const data = await VerifyEmail(payload);
      // console.log({ data });

      toast.remove();
      toast.success(data?.message);
      toast.loading("Logging in...");
      setTimeout(() => {
        loginMutation.mutate({
          account_type,
          email,
          password,
        });
        // .then(() => setScreenId(3));
      }, 1000);

      // setOtpStatus(true);
      //   sessionStorage.setItem("resetPasswordEmail", email);

      // setTimeout(() => {
      //   setScreenId(3);
      // }, 1000);
    } catch ({ response }) {
      //   console.log(response);
      toast.remove();
      toast.error(response?.data?.message);
    }
  };

  const handleResend = async () => {
    toast.loading("Processing...");
    try {
      const data = await ResendOTP({ email });
      toast.remove();
      toast.success(data?.message, { duration: 3000 });
    } catch ({ response }) {
      toast.remove();
      toast.error(response?.data?.message);
    }
  };
  return (
    <div
      className="rounded-lg w-full mx-auto p-3 bg-white"
      style={{ maxWidth: "600px" }}
    >
      <div className="w-full flex items-center hidden justify-end">
        <button onClick={handleModal}>
          <FontAwesomeIcon icon={faXmark} />
        </button>
      </div>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <p>Email</p>
        {email ? (
          <span className="block text-smFont w-full border border-slate-300 bg-slate-100 cursor-not-allowed p-2 rounded-md mb-4 outline-none focus:ring-0 focus:border-armyGreen">
            {email}
          </span>
        ) : (
          <input
            className="block text-smFont w-full border border-slate-300 p-2 rounded-md mb-4 outline-none focus:ring-0 focus:border-armyGreen"
            type="email"
            onChange={(e) => setClientEmail(e.target.value)}
            placeholder="Enter your E-mail"
          />
        )}
        <p>O.T.P</p>
        <input
          className="block text-smFont w-full border border-slate-300 p-2 rounded-md mb-4 outline-none focus:ring-0 focus:border-armyGreen"
          type="text"
          {...register("otp", {
            required: true,
          })}
          placeholder="Enter your O.T.P"
        />
        <input
          id="submit"
          type="submit"
          value="Submit"
          className="hover:text-400 text-smFont w-full mt-4 mx-auto rounded-md cursor-pointer bg-armyGreen text-white py-2"
        />
      </form>
      {email && (
        <span
          className="w-full flex items-center justify-center gap-1 mt-3"
          style={{ fontSize: "12px", fontWeight: "500" }}
        >
          O.T.P not received?
          <button
            onClick={handleResend}
            className="hover:underline text-smFont text-armyGreen"
          >
            Click to resend
          </button>
        </span>
      )}
    </div>
  );
};

export default VerifyEmail;
