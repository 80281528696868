import React from 'react'
import Footer from '../../layout/Footer'
import UpdatedNavbar from '../../layout/UpdateNavbar'
import { useParams } from 'react-router-dom'
import Jumbotron from '../../components/Jumbotron'
import UpdateProfile from './components/UpdateProfile'

type linkType = {
  path: string,
  title: string,
  slug: string,
}

const SubmitApllication: React.FC = (): React.ReactElement => {

    const { id } = useParams();
    const links_: linkType[] = [
        {
          path: "/",
          title: "Home",
          slug: "home",
        },
        {
          path: "/job-listings",
          title: "Job Listings",
          slug: "job-listings",
        },
        {
          path: `/job-listings/apply/${id}`,
          title: "Apply",
          slug: "job-details",
        },
        {
          path: `/job-listings/submit-application/${id}`,
          title: "Submit Application",
          slug: "submit-application",
        },
      ];
      
  return (
    <div className='force-white-mode'>
        <UpdatedNavbar />
         <section className='pt-2 pl-2'>
           <Jumbotron links={links_} active={links_[links_.length - 1 ].slug} />
           <section>
               <UpdateProfile />
           </section>
         </section>
        <Footer />
    </div>
  )
}

export default SubmitApllication