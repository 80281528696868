import axios, { AxiosInstance } from "axios";
import baseUrl from "../url";

export const createApiClient = () : AxiosInstance => {

const client: AxiosInstance = axios.create({
  baseURL: baseUrl,
  headers: {
    "Cache-Control": "no-cache",
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

client.interceptors.request.use(
  async (config: any) => {
    const token = sessionStorage.getItem("userToken");

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

client.interceptors.response.use(
  ( response: any) => response,
  ( error: any) => {
    if (error?.response?.status === 401) {
      sessionStorage.setItem("is-authenticated", "0");
    }
    return Promise.reject(error);
  }
);
 return client
}