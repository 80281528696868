import React from 'react'

const ReleaseNotes = () => {
  return (
    <div className='p-3'>
        <h5 className='text-center mt-8 text-xl font-semibold'>
           Release Notes
        </h5>
    </div>
  )
}

export default ReleaseNotes