import React from "react";
import { Link } from "react-router-dom";

const JobCard = ({
  title,
  company,
  type,
  listing,
  location,
  date,
  link,
  status,
}) => {
  const timeAgo = (date) => {
    const currentDate = new Date();
    const timestamp = date.getTime();
    const currentTimestamp = currentDate.getTime();
    const timeDifference = currentTimestamp - timestamp;

    // Define time units in milliseconds
    const minute = 60 * 1000;
    const hour = 60 * minute;
    const day = 24 * hour;
    const week = 7 * day;
    const month = 30 * day;
    const year = 365 * day;

    if (timeDifference < minute) {
      return "Just now";
    } else if (timeDifference < hour) {
      return Math.floor(timeDifference / minute) + "m ago";
    } else if (timeDifference < day) {
      return Math.floor(timeDifference / hour) + "h ago";
    } else if (timeDifference < week) {
      return Math.floor(timeDifference / day) + "d ago";
    } else if (timeDifference < month) {
      return Math.floor(timeDifference / week) + "w ago";
    } else if (timeDifference < year) {
      return Math.floor(timeDifference / month) + "mo ago";
    } else {
      return Math.floor(timeDifference / year) + "y ago";
    }
  };

  const setActiveCampaign = () => {
    sessionStorage.setItem("active-listing", JSON.stringify(listing));
  };

  return (
    <div className={`rounded-lg border shadow-sm ${
       status ? 'border-armyGreen' : 'border-gray-200'
    }`}>
      {status ? (
        <span className="block pl-1 text-white text-xs pt-1 job-batch-card bg-armyGreen h-[30px] w-[120px]">
          Open
        </span>
      ) : (
        <span className="block pl-1 text-white text-xs pt-1 job-batch-card bg-red-500 h-[30px] w-[120px]">
          Closed
        </span>
      )}
      <div className="p-3">
        <h5 className={`text-xl mt-2 capitalize ${ !status ? 'text-gray-900' : 'text-armyGreen'}`}>{title}</h5>
        <p className={`mt-2 mb-3 text-sm capitalize ${ !status ? 'text-gray-900' : 'text-armyGreen'}`}>{company}</p>
        <div className="flex justify-end">
          <button className="py-2 rounded text-[11px] bi bi-geo-alt bg-gray-100 px-3 mr-4">
            &nbsp;{location}
          </button>
          <button className="py-2 rounded text-[11px] bi bi-briefcase bg-gray-100 px-3 mr-4">
            &nbsp;{type}
          </button>
          <button className="py-2 rounded text-[11px] bi bi-alarm bg-gray-100 px-3 mr-4">
            &nbsp;{timeAgo(date)}
          </button>
        </div>
        <div className="mt-4 flex justify-end">
          {  listing?.type === 'external' ?
            <a
             target="_blank"
             className="text-sm text-armyGreen"
             href={listing?.external_link}
           >
             View Details{" "}
             <span className="ml-1 bi bi-chevron-double-right"></span>
           </a>
          :
          <Link
            onClick={setActiveCampaign}
            to={link}
            className="text-sm text-armyGreen"
          >
            View Details{" "}
            <span className="ml-1 bi bi-chevron-double-right"></span>
          </Link>}
        </div>
      </div>
    </div>
  );
};

export default JobCard;
