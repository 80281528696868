import React from 'react'

const NotificationModal = ({ onClose, children }) => {
  return (
    <div id='nofication-modal' style={{display: 'none', zIndex: 20000}} className='w-[100vw]  h-[100vh] bg-[rgba(0,0,0,0.3)] fixed left-0 top-0'>
         <div className='flex justify-center items-center w-full h-full p-0 m-0'>
            <section className='bg-white w-[300px] md:w-[400px] p-3 h-auto rounded-lg'>
               <h5 className='flex justify-end'>
                  <span 
                     className='bi bi-x-lg dark:text-slate-900 text-lg' 
                     onClick={ () => {
                        sessionStorage.setItem('has-exam', '0')
                        document.querySelector('#nofication-modal').style.display = 'none'
                     } } 
                  />
               </h5>
               <section>
                  {children}
               </section>
            </section>
         </div>
    </div>
  )
}

export default NotificationModal