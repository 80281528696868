import React from 'react'

const Card = ({className, title, text, img}) => {
  return (
    <div className={className}>
         <img 
            src={img} alt="why choose us image" 
            className='w-full h-[200px] rounded-0'
          />
        <h5 className='font-semibold  hidden  text-gray-950 text-lg mb-2 mt-3 text-center'>{title}</h5>
        <p className='text-[15px] py-3 text-gray-950 px-2'>{text}</p>
    </div>
  )
}

export default Card