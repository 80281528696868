import React  from 'react'
import UpdatedNavbar from '../../layout/UpdateNavbar'
import Spinner from './../../components/Spinner';
import { useSearchParams, useNavigate, Link } from 'react-router-dom';
import { api } from '../../api';
import { useQuery } from 'react-query';
import { toast } from 'react-hot-toast';
import { useCustomToast } from '../../components/customtoast/CustomToastProvider';
import { hashSync } from "bcryptjs";

const Verification = () => {

  const [searchParams] = useSearchParams();
  const [accountType, setAccounType] = React.useState("candidate");
  const email = searchParams.get('email');
  const navigate = useNavigate() 
  const hash = searchParams.get('h');
  const campaign_id = searchParams.get('campaign_id');
  const campaign_slug = searchParams.get('campaign_slug');
  const affiliate_slug = searchParams.get('affiliate_slug');
  const redirect_url = localStorage.getItem("redirect-url");
  const customToast = useCustomToast(); 

  const { isLoading, isError } = useQuery(["verify otp"],  () => {
    return api.verifyEmail({ type: 'link', email, hash })
   }, {
   onSuccess: data => {     
      toast.success('Verification successfull')
      sessionStorage.setItem("userToken", data.data.token);
      sessionStorage.setItem("user", JSON.stringify(data?.data));
      sessionStorage.setItem("account-type", hashSync(accountType, 10));
      sessionStorage.setItem("is-authenticated", "1"); 
      navigate(`/job-listings/apply/${campaign_slug}?campaign-id=${campaign_id}&auth=true${
       affiliate_slug ? `&affiliate-id=${affiliate_slug}` : ''
      }`)
   },
   onError: error => {
      if( error?.response?.status === 400 ){
        navigate(`/job-listings/apply/${campaign_slug}?campaign-id=${campaign_id}&auth=false&action=login${
          affiliate_slug ? `&affiliate-id=${affiliate_slug}` : ''
         }`)
         toast.error('Error! Email already verified please login.')
      }
   },
   retry: false
});

  return (
    <section>   
       <UpdatedNavbar />
       <section className='mt-12'>
              { isLoading &&
                <div>
                  <Spinner text='Verifying...' />
               </div>
              }
             {/*
              isError &&
              <div className='bg-white w-[300px] mx-auto flex flex-column justify-center mt-8 md:mt-[160px]'>
                 <span className='text-7xl text-center text-red-500 bi bi-bug'></span>
                 <h5 className='text-md text-center'>
                     <strong className='text-lg'>Opps!</strong> There was an error with this verification method.
                      you can try OTP method
                 </h5>
                 <Link to={`/email-verification?email=${email}`} 
                   className='text-center mt-3 bg-armyGreen text-white text-sm py-3 px-12 rounded-full'
                  >
                    Verify with OTP
                 </Link>
            </div>*/}
       </section>
    </section>
  )
}

export default Verification