import React from 'react'
import { ScaleLoader } from 'react-spinners'

const Loader = () => {
  return (
    <div className="flex justify-center py-32 md:py-44">
         <ScaleLoader color="#8EAF55"  />
     </div>
  )
}

export default Loader