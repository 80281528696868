import React from 'react'

const NoJob = () => {
  return (
    <div className='my-5'>
        <h1 className='bi text-center bi-search text-gray-600 text-[100px] mb-2'></h1>
        <p className='text-md text-center'>  
                 <span className='text-lg text-armyGreen'>Sorry</span>, 
                 there was no any job found for this search.
         </p>
    </div>
  )
}

export default NoJob