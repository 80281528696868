import React from 'react'
import UpdatedNavbar from '../../layout/UpdateNavbar'
import Jumbotron from '../../components/Jumbotron'
import lady1Image from '../../images/assets/image 6.png'
import WhyChooseUs from './components/WhyChooseUs'
import Footer from '../../layout/Footer'
import NewsLetterSubscribe from './components/NewsLetterSubscribe'

const AboutUs = () => {

    const links_ = [ 
        { 
            path : '/',
            title : 'Home',
            slug : 'home',
         },
         { 
            path : '/about-us',
            title : 'About Us',
            slug : 'about-us',
         }      
     ]

  return (
    <div className='px-2 force-white-mode'>
        <UpdatedNavbar />
         <div className='ml-1'>
         <Jumbotron 
           active={'about-us'}
           links={links_}
        />
         </div>
        <section className='mt-3 w-full md:w-[70%] mx-auto md:flex items-center'>
            <img src={lady1Image} alt="about us" className='h-[300px] object-cover rounded-full my-3 w-[300px]' />
            <div className='md:ml-[100px] p-5 rounded-lg'>
                <h5 className='text-xl  text-gray-950 mb-2'>What We Do</h5>
                <p className='mb-2 text-gray-950'>
                "We create personalised, realistic job
                 assessment that authentically 
                communicate what’s it’s like to work within a
                 specific role in your organisation"
                </p>
                 <img src={'/assets/list1.svg'} alt="list item" />
                 <p className='my-2'>
                    <img src={'/assets/list2.svg'} alt="list item" />
                 </p>
                 <img src={'/assets/list3.svg'} alt="list item" />
            </div>
        </section>
        <WhyChooseUs />
        <NewsLetterSubscribe />
        <Footer />
    </div>
  )
}

export default AboutUs