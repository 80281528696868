const getBaseUrl = () => {
  const hostname = window.location.hostname;
  const ipHost = window.location.host.includes(":3000");
  if (
    hostname.includes("playpen") ||
    hostname.includes("localhost") ||
    ipHost
  ) {
    return process.env.REACT_APP_TEST_URL;
  } else {
    return process.env.REACT_APP_BASE_URL;
  }
};
const URL = getBaseUrl();

export default URL;
