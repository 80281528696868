import React from "react";
import { useNavigate, Link } from "react-router-dom";
import logo from "./images/assets/TestAssessify_logo.png";


function NotFound() {
  return (
    <div className="relative h-screen  bg-white">
      <div className="w-full p-3 h-26  sticky top-0 cursor-pointer">
        <div className="h-full">
          <img
            src={logo}
            alt="Test Assessify"
            className="w-56 h-full object-contain"
          />
        </div>
      </div>
      
      
      <div className="grid place-content-center h-full">
        <div className="relative z-10 flex flex-col items-center w-full font-mono">
        <p className="font-extrabold text-primary text-8xl  animate-bounce italic">
            Oops!
          </p>
          <h1 className="mt-4 text-5xl font-bold leading-tight text-center text-primary mb-10">
            404 -PAGE NOT FOUND
          </h1>
        
          <Link to="/" className="text-darkBlue text-xl border rounded-lg border-primary p-4">
            Go back home
          </Link>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
