

const Paragraph = ({text, textStyles}) => {
  return (
    <p style={{ ...commonStyles, ...textStyles }}>{text}</p>
  )
}

export default Paragraph

const commonStyles = {
    margin: "1.5rem 0",
    color: "var(--ash)"
}