 import React from 'react'
import UpdatedNavbar from '../../layout/UpdateNavbar'
import VerifyEmail from '../JobApply/modals/VerifyEmail';
 
 const verifyEmail = () => {
   return (
     <div>
        <UpdatedNavbar />
        <div className='flex justify-center mt-8 md:mt-[100px]'>
              <div className='w-[96%] md:w-[450px] shadow-xl rounded-xl border border-gray-200'>
                  <h5 className='text-xl pt-3 text-center'> Email Verification</h5>
                  <p className='text-center text-xs mt-2'>
                    Verify your email address by providing the OTP code sent to your email.
                  </p>
                 <VerifyEmail />
              </div>
        </div>
     </div>
   )
 }
 
 export default verifyEmail