import { Col, Container, Row } from "react-bootstrap";
import CustomButton from "../components/CustomButton";
import Header from "../components/Header";
import Paragraph from "../components/Paragraph";
import whatWeDo from "../images/assets/What do we do.JPG";
import topTalent from "../images/assets/top talent.JPG";
import Candidates from "../images/assets/businesswoman-consulting-legal-experts-1.png";
import relevantData from "../images/assets/businesswoman-consulting-legal-experts-2.png";
import Simulation from "../images/assets/businesswoman-consulting-legal-experts.png";
import positiveExperience from "../images/assets/positive experience.JPG";
import Footer from "../layout/Footer";
import { BiBuildings } from "react-icons/bi";
import { IoIosPeople } from "react-icons/io";
import { BsStarFill } from "react-icons/bs";
import UpdatedNavbar from "../layout/UpdateNavbar";
import NewsLetterSubscribe from './AboutUs/components/NewsLetterSubscribe';

const Home = () => {

  return (
    <>
      <UpdatedNavbar />
      <div className="landing-page-image" style={{marginTop: 0}}>
        <Container>
          <Row>
            <Col md={6} sm={6} style={{ marginTop: "15%" }}>
              <Header
                titleStyles={HeaderStyling}
                title="Hire the best-accurate, fair and fast recruitment!"
              />
              <Paragraph
                text="Our screening tests identify the best candidates and make your hiring decisions faster, easier, and bias-free."
                textStyles={textStyles}
              />
              <CustomButton
                title="Request a demo"
                customButtonStyling={talkToUs}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="text-center" style={{ padding: "50px 0" }}>
        <Header
          titleStyles={HeaderStyling}
          title="Join the companies replacing CVs with TestAssessify"
        />
      </div>
      <Row style={{ background: "#FAFBFC", padding: "5rem 0", margin: "0" }}>
        <Col sm={12} md={6} lg={7}>
          <img
            src={whatWeDo}
            alt="main-background"
            className="w-100"
            style={{ marginLeft: "-3rem" }}
          />
        </Col>
        <Col sm={12} md={6} lg={5} className="m-auto">
          <Container>
            <Header titleStyles={HeaderStyling} title="What we do" />
            <Paragraph
              text="We create personalised, realistic job assessment that authentically communicate what’s it’s like to work within a specific role in your organisation."
              textStyles={textStyles}
            />
            <ul>
              <li className="d-flex align-items-center mb-3">
                <IoIosPeople size={30} />{" "}
                <span style={{ marginLeft: ".6rem" }}>
                  Select the best candidates
                </span>
              </li>
              <li className="d-flex align-items-center mb-3">
                <BiBuildings size={25} />{" "}
                <span style={{ marginLeft: "1rem" }}>
                  Make your recruitment seamless
                </span>
              </li>
              <li className="d-flex align-items-center mb-3">
                <BsStarFill size={25} />{" "}
                <span style={{ marginLeft: "1rem" }}>
                  Grade and rank candidates
                </span>
              </li>
            </ul>
          </Container>
        </Col>
      </Row>
      <Container>
        <Row>
          <div className="text-center mb-5 mt-5">
            <Header titleStyles={HeaderStyling} title="Why you should use us" />
          </div>

          <Col sm={12} md={4}>
            <img src={Simulation} alt="grid-repeat" className="w-100" />
            <div className="mt-5">
              <Header
                titleStyles={benefitHeaderStyles}
                title="Candidates"
                fontSize={benefitHeaderStyles.fontSize}
              />
              <Paragraph
                text="Candidates feel they have been treated fairly and leave the experience as fans of your brand even if they do not get the job."
                textStyles={textStyles}
              />
            </div>
          </Col>
          <Col sm={12} md={4}>
            <img src={Candidates} alt="grid-repeat" className="w-100" />
            <div className="mt-5">
              <Header
                titleStyles={benefitHeaderStyles}
                title="Relevant Data"
                fontSize={benefitHeaderStyles.fontSize}
              />
              <Paragraph
                text="You evaluate candidates based on objective, job-relevant data and demonstrated abilities using a multi-method approach.."
                textStyles={textStyles}
              />
            </div>
          </Col>
          <Col sm={12} md={4}>
            <img src={relevantData} alt="grid-repeat" className="w-100" />
            <div className="mt-5">
              <Header
                titleStyles={benefitHeaderStyles}
                title="Simulation"
                fontSize={benefitHeaderStyles.fontSize}
              />
              <Paragraph
                text="Candidates engage in a series of simulated tasks and job-relevant exercises customised for your brand."
                textStyles={textStyles}
              />
            </div>
          </Col>
        </Row>
      </Container>

      <Row style={{ background: "#FFFCF5", margin: "0" }}>
        <Col sm={12} md={6} lg={7} className="m-auto">
          <Container style={{ width: "85%" }}>
            <Header
              titleStyles={greenButtonStyling}
              title="Offer a positive candidate experience."
            />
            <Paragraph
              text="Assessments are proven to provide candidates a better experience and greater satisfaction by giving them the opportunity to engage in job relevant tasks and demonstrate skills related to the job."
              textStyles={textStyles}
            />
          </Container>
        </Col>
        <Col sm={12} md={6} lg={5}>
          <img src={positiveExperience} alt="what-we-do" className="w-100" />
        </Col>
      </Row>
      <Container>
        <Row>
          <Col sm={12} md={6} lg={6}>
            <img
              src={topTalent}
              alt="main-background"
              height="650"
              width="100%"
            />
          </Col>
          <Col sm={12} md={6} lg={6} className="m-auto">
            <Header
              titleStyles={greenButtonStyling}
              title="Top talent is priceless. We make finding them affordable and easier"
            />
            <Paragraph
              text="A time-consuming screening process costs you a lot. Painful mis-hires cost you much, much more. With TestAssesify, you avoid all of this and see a huge return on investment every single year. Start screening with us today on our free plan."
              textStyles={textStyles}
            />
            <CustomButton title="Talk to us" customButtonStyling={talkToUs} />
          </Col>
        </Row>
      </Container>
      <div className="my-4">
         <NewsLetterSubscribe />
      </div>
      <Footer />
    </>
  );
};

export default Home;

const HeaderStyling = {
  color: "#19144D",
  // textAlign: 'center'
};

const benefitHeaderStyles = {
  color: "#19144D",
  fontSize: "24px",
};
const greenButtonStyling = {
  color: "#19144D",
};
const customButtonStyling = {
  background: "var(--orange)",
  color: "var(--white)",
  padding: ".7rem 2rem",
  border: "none",
};
const talkToUs = {
  background: "#FDB919",
  color: "var(--white)",
  padding: ".7rem 2rem",
  border: "none",
};
const textStyles = {
  color: "#19144D",
};
