
const Spinner = ({text='Loading...'}) => {
  return (
    <>
        <div style={spinnerWrap}>
            <div style={spinner}>
                {text}
            </div>
        </div>
    </>
  )
}

export default Spinner


const spinnerWrap = {
    position: "fixed",
    width: "100vw",
    height: "100vh",
    display: "flex",
    background: "rgb(0 0 0 / 52%)",
    top: "0",
    left:"0",
    zIndex: "10000000",
}
const spinner = {
    margin: "auto",
    color: "var(--white)"
}