import React, { useState,forwardRef } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const Input = ({ name, label, rules, isIcon, type, ...rest }) => {
// const Input = forwardRef(({ name, label, rules, isIcon, type, ...rest }, ref) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="w-full">
      {label &&
      <label htmlFor={name} className="mb-4 text-lightGrey text-xs">
        {label}
      </label>}
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => (
          <div className="relative">
            <input
              // ref={ref} 
              name={name}
              {...rest}
              {...field}
              type={showPassword ? "text" : type} // Toggle password visibility
              className={`flex-1 appearance-none ${
                isIcon ? "rounded-r-lg" : "rounded-lg"
              } border ${
                errors[name]
                  ? "border-red-500 focus:ring-red-500"
                  : "border-gray-300"
              } w-full py-3 px-4 2xl:py-3 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-sm focus:outline-none focus:ring-2 focus:ring-[#88a848] focus:border-transparent`}
            />
            {type === "password" && (
              <button
              type="button"
                className="absolute right-4 top-1/2 transform -translate-y-1/2"
                onClick={togglePasswordVisibility}
              >
               {showPassword ? (
                            <AiOutlineEye className="w-6 h-6"/>
                          ) : (
                            <AiOutlineEyeInvisible className="w-6 h-6" />
                          )}
              </button>
            )}
            {errors[name] && (
              <p className="text-red-500 mt-1 ">{errors[name].message}</p>
            )}
          </div>
        )}
      />
    </div>
  );
}

export default Input;
